/* global */

@font-face {
  font-family: 'Jalnan';
  src: url('./asset/font/JalnanOTF.otf');
}

@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700');

html {
  font-family: 'Nanum Gothic', sans-serif;
}

.fullwidth {
  width: 100%;
}

.fullheight {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #fbfbfb;
}

.main_container {
  box-sizing: border-box;
  padding-top: 66px;
}

.main {
  max-width: 1080px;
  margin: 12px auto 50px auto;
  padding: 0 12px;
}

.header {
  max-width: 100%;
  padding: 0 12px;
}

h1 {
  margin: 0;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
}

li {
  list-style: none;
}

footer {
  margin-top: auto;
  height: 60px;
  background-color: #eee;
  text-align: center;
  padding-top: 25px;
  z-index: 3;
}

footer span,
footer a {
  font-size: 12px;
  color: #999;
}

.key_color {
  color: #ff0045;
}

.key_color_hv a:hover {
  color: #ff0045;
}

.key_color_bg {
  background-color: #ff0045;
}

.key_font {
  font-family: 'Jalnan';
  font-weight:400;
}

.main_copy {
  font-family: 'Jalnan';
  font-size: 1.9rem;
  font-weight:400;
  line-height: 2.7rem;
}

.search_no_msg .main_copy {
  font-family: 'Jalnan';
  font-size: 1.5rem;
  font-weight:400;
  line-height: 2.2rem;
}

.home_ico {
  background: no-repeat center/160px url('./asset/images/ico_main@2x.png');
  height: 220px;
}

/* global > header */

.header_container {
  position: fixed;
  z-index: 5;
  height: 66px;
}

.header {
  position: relative;
  height: 100%;
  font-family: 'Jalnan';
  font-weight:400;
}

/* index page */

.logo_area_main {
  position: relative;
  top: 14px;
  text-align: center;
}

.main_copy {
  text-align: center;
  padding: 100px 0 50px 0;
}

.search_main {
  margin: 0 auto;
  width: 284px;
  position: relative;
}

@media (min-width: 480px) {
  .mb_view {
    display: none;
  }
}

@media (max-width: 480px) {
  .main_copy {
    padding: 50px 0 50px 0;
  }

}

/* search result page */

.search_count,
.loading {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

.loading {
  padding-top: 16px;
  height: 80px;
}

.loading_ico {
  display: inline-block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  background: no-repeat center/20px url('./asset/images/spinner-solid@2x.png');
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading_ico {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.box_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}

.box_container::after {
  content:"";
  flex:auto;
}

.box_item {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  flex: 0 1 31%;
  margin: 1%;
}

.box_item:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

.box_img,
.box_img_overlay {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.box_img {
  height: 200px;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.box_img_overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.box_rank {
  width: 64px;
  height: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 3;
}

.box_rank_num {
  font-family: 'Jalnan';
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  position: relative;
  top: 5px;
  display: block;
  text-align: center;
}

.box_insta_count {
  width: 40px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 16px;
  z-index: 3;
}

.box_insta_count_txt {
  color: white;
  text-align: right;
  font-weight: 700;
  font-size: 13px;
  display: block;
  background-image: url('./asset/images/instagram-brands.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 12px;
}

.box_txt {
  padding: 16px;
  color: #090909;
}

.box_title,
.box_title a {
  font-size: 20px;
  padding-top: 10px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.box_desc,
.box_desc a {
  font-size: 15px;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 20px;
}

@media (min-width: 481px) and (max-width: 900px) {
  .box_item {
    flex: 0 1 47%;
    margin-bottom: 12px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .box_item {
    flex: 0 1 100%;
    margin-bottom: 12px;
  }
}

/* search result none page */

.search_no_msg {
  width: 100%;
  margin: 40px auto;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
}

.sch_no_ico {
  display: block;
  width: 100%;
  height: 100px;
  background-image: url('./asset/images/sample_search.png');
  background-repeat: no-repeat;
  background-position: center;
}

.keyword_suggest {
  width: 300px;
  margin: 0 auto;
}

/* detail page */

.goto_list a {
  font-size: 15px;
  color: #ff0045;
  margin-left: 20px;
}

.goto_list a {
  display: inline-block;
  height: 18px;
  width: 100%;
  padding-left: 20px;
  background: no-repeat left/10px url('./asset/images/chevron-left-solid@2x.png');
}

.related_box_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 140px;
  margin: 40px 0;
}

.related_prv,
.related_nxt {
  height: 110px;
  width: 30px;
  flex: 30px 30px;
}
.related_prv {
  background: no-repeat center/20px url('./asset/images/caret-left-solid@2x.png');
}

.related_nxt {
  background: no-repeat center/20px url('./asset/images/caret-right-solid@2x.png');
}

.related_box_list_wrap {
  overflow: auto;
  overflow-y: hidden;
  width: 90vw;
  flex: 1 1;
  margin: 0 12px;

}

.related_box_list {
  flex: 1 1;
  width: 100%;
  margin: 0px;
  height: 130px;
  overflow-wrap: unset;
  display: flex;
  justify-content: space-between;
}

.related_box_item {
  display: inline-block;
  height: 128px;
  width: 100px;
  margin: 0 4px;
}

.related_box_img {
  box-sizing: border-box;
  height: 100px;
  border-radius: 12px;
  background-size: cover;
  position: relative;
}

.related_box_rank {
  z-index: 3;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 30px;
  height: 24px;
  color: white;
  text-align: center;
  background-color: #ff0045;
}

.related_box_rank_num {
  font-family: 'Jalnan';
  font-weight:400;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  top: 4px;
  display: block;
  width: 30px;
  text-align: center;
}

.related_box_txt {
  padding: 0;
}

.related_box_txt h3 {
  font-size: 12px;
  margin: 6px 0;
}

.related_box_txt p {
  display: block;
  height: 24px;
  overflow: hidden;
  font-size: 12px;
  margin: 4px 0;
}

.detail_title {
  padding: 30px 0 16px 0;
  font-size: 28px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 32px;
}

.detail_txt {
  font-size: 16px;
  padding-left: 20px;
}

.detail_map_link {
  font-weight: 700;
}

.map_ico {
  display: inline-block;
  height: 20px;
  width: 100%;
  margin-left: 20px;
  background: no-repeat left/15px url('./asset/images/map-marker-alt-solid@2x.png');
}

.deatil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.detail_map {
  height: 300px;
  flex: 1 360px;
  border-radius: 15px;
  background-size: cover;
  background-position: center center;
}

.detail_desc {
  flex: 2 360px;
  display: block;
}

.insta_count {
  margin-top: 32px;
  padding-top: 60px;
  font-size: 16px;
  border-top: 1px solid #ccc;
}

.insta_box_item {
  box-shadow: none;
  border: solid 1px #ddd;
  height: 130px;
  border-radius: 12px;
  margin-bottom: 16px;
}

.insta_box_item:hover {
  color: #ff0045;
  border: solid 1px #ff99b5;
  background-color: #fff2f6;
}

.insta_box_img {
  margin:10px 0 10px 10px;
  height: 110px;
  width: 110px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  float: left;
}

.insta_box_txt {
  height: 150px;
  overflow: hidden;
}

.insta_box_id {
  font-weight: 700;
}

.insta_box_tags,
.insta_box_id {
  font-size: 12px;
  line-height: 18px;
  color: #333;
  padding: 10px 10px;
}

@media (min-width: 481px) and (max-width: 999px) {
  .related_prv {
    display: none;
  }

  .related_box_list_wrap {
    margin: 0;
  }
}
